.users {
  display: flex;
  align-content: center;
  justify-content: space-between;
  .user-details {
    width: 100%;
  }
}
.users-content {
  padding: 0 16px 16px;
  .users-search {
    margin-bottom: 20px;
  }

  .users-table {
    .ant-table-thead > tr > th {
      background: @white-color;
      color: @grey-color;
    }
  }
}

.users-card {
  overflow-y: auto;
  width: 100%;
  .ant-card-bordered {
    margin-bottom: 10px;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & h1 {
      font-size: 18px;
    }
  }
  .card-content p {
    margin-bottom: 0;
  }
}
