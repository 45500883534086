#root {
  height: 100vh;
}

.header-notification {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}
.ant-table-pagination.ant-pagination {
  margin-bottom: 0;
  align-items: center;
}
.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 16px 24px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 24px;
}
.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid @grey-5;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid @grey-5;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid @grey-5;
    border-radius: 0;
  }
}
.ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 40px;
}
.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid @light-orange;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: @peach;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

//CUSTOM STYLE CLASSES START
.break-word {
  word-break: break-word;
}
//margin & padding style
.m-0 {
  margin: 0;
}
.mt-20 {
  margin-top: 20px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-25 {
  margin-right: 25px;
}

.ml-25 {
  margin-left: 25px;
}
.ml-15 {
  margin-left: 15px;
}

.fs-x-large {
  font-size: x-large;
}

.p-0 {
  padding: 0;
}
//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}
.vertically-middle {
  vertical-align: middle;
}
.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}
// flex-box related style

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}
.width-percent-100 {
  width: 100%;
}
.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.fill-width {
  width: 100%;
}
//width classes

//cursor
.pointer {
  cursor: pointer;
}
//cursor

//border style
.b-0 {
  border: none;
}
//border style

.ant-popover-title {
  display: flex;
  justify-content: center;
}
//CUSTOM STYLE CLASSES END

.gx-user-popover {
  li {
    span {
      margin-right: 15px;
    }
  }
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  & button {
    margin-right: 10px;
    border: 1px solid @border-color;
    font-weight: 500;
  }

  .cancel-button {
    background: @light-blue;
    border-radius: 40px;
    color: @grey-color;
    border-color: @light-blue;
  }

  .save-button {
    background: @primary-color;
    color: @white-color;
  }
}

.change-password-content {
  display: flex;
  justify-content: center;
}

.change-password-inside-content {
  max-width: 600px;
  width: 94%;
  margin-top: 25px;
  padding: 20px 20px 20px;
  background-color: @white-color;
  .border-radius(@border-radius-lg);
  .box-shadow(@gx-card-shadow);
  font-size: 14px;
  position: relative;
  z-index: 5;
  h1 {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: @h2-font-size;
  }
  #change-password {
    .ant-row.ant-form-item {
      margin-bottom: 10px;
    }
  }
  .form-buttons {
    margin-top: 15px;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  margin-top: 5px;
  font-size: 12px;
}
.ant-btn,
.ant-tag {
  border: none;
  box-shadow: none;
}

.wrapper-404 {
  min-height: calc(100vh - 50px - 72px - 40px);
  min-width: auto;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-msg {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 6px;
  }
  .home-link {
    font-size: 20px;
  }
}

.ant-input,
.ant-select-selection-placeholder,
.ant-input-number-input,
.ant-picker-input > input {
  font-size: 14px;
}

.alert-div {
  background-color: #fff1f0;
  display: flex;
  justify-content: center;
  padding: 5px 0px;
}
