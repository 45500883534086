* {
  font-family: 'Rubik', sans-serif;
}

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

code {
  font-family: 'Rubik', sans-serif;
}
